@import "../../theme";

.avatar {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 20%;
  border: 8px solid black;
  box-shadow: 10px 5px 5px $base-secondary-color;
}

.headline {
  margin-top: 16px;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  max-width: 500px;
}

.highlight {
  color: $base-color;
}

.intro {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light-color;
}
