.projectSection {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0px 24px;
  img {
    filter: brightness(80%);
    width: 400px;
    border-radius: 25px;
  }
}

.projectLinks {
  display: flex;
  a {
    margin: 0 12px;
    text-decoration: none;
    border-bottom: dotted black 1px;
    color: rgba($color: #000000, $alpha: 0.8);
  }
}

.projectCollection {
  h3 {
    font-size: 1.5rem;
  }
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .projectCollection {
    flex-direction: row;
    align-items: flex-start;
  }
}
