@import url(https://fonts.googleapis.com/css?family=Inconsolata:400);
.About_about__3G5xn ul{padding-left:16px;list-style-type:disc}.About_about__3G5xn a{text-decoration:none;border-bottom:dotted black 1px;color:rgba(0,0,0,0.8)}

.Conferences_conferenceSection__1Ubez{display:flex;flex-direction:column;max-width:100%}.Conferences_conference__XZdFf{display:flex;flex-direction:column;align-items:center;max-width:800px;margin:0px 24px}.Conferences_description__1_Nbx{margin-top:8px}.Conferences_conferenceLinks__3QN3j{display:flex}.Conferences_conferenceLinks__3QN3j a{margin:0 12px;text-decoration:none;border-bottom:dotted black 1px;color:rgba(0,0,0,0.8)}.Conferences_conferenceCollection__2HTmv{display:flex;justify-content:space-around;flex-direction:column;align-items:center}.Conferences_conferenceCollection__2HTmv h3{font-size:1.5rem;margin:0}.Conferences_conferenceCollection__2HTmv h4{margin:0;font-weight:400}.Conferences_conferenceCollection__2HTmv h5{margin:0;margin-bottom:8px;font-weight:400}@media (min-width: 768px){.Conferences_conferenceCollection__2HTmv{flex-direction:row;align-items:flex-start}}

.Contact_contact__1na2B a{text-decoration:none;border-bottom:dotted black 1px;color:rgba(0,0,0,0.8)}

.Intro_avatar__1u9Y1{width:100%;max-width:200px;height:auto;border-radius:20%;border:8px solid black;box-shadow:10px 5px 5px #49a078}.Intro_headline__3gMk0{margin-top:16px;font-weight:500;font-size:2rem;text-align:center;max-width:500px}.Intro_highlight__3Lude{color:#216869}.Intro_intro__2WoDY{padding:20px 0px;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#dce1de}

.Projects_projectSection__1Y37h{display:flex;flex-direction:column;max-width:100%}.Projects_project__23-Fz{display:flex;flex-direction:column;align-items:center;max-width:400px;margin:0px 24px}.Projects_project__23-Fz img{-webkit-filter:brightness(80%);filter:brightness(80%);width:400px;border-radius:25px}.Projects_projectLinks__3PGtR{display:flex}.Projects_projectLinks__3PGtR a{margin:0 12px;text-decoration:none;border-bottom:dotted black 1px;color:rgba(0,0,0,0.8)}.Projects_projectCollection__1-fi_{display:flex;justify-content:space-around;flex-direction:column;align-items:center}.Projects_projectCollection__1-fi_ h3{font-size:1.5rem}@media (min-width: 768px){.Projects_projectCollection__1-fi_{flex-direction:row;align-items:flex-start}}

.Footer_footer__1mb_M,.Footer_footer__1mb_M ul{display:flex;flex-direction:row}.Footer_footer__1mb_M a{text-decoration:none;color:#dce1de}.Footer_footer__1mb_M{background-color:#49a078;justify-content:center;width:100%;margin-top:12px;min-height:20px}.Footer_footer__1mb_M li{margin:0 4px}.Footer_footer__1mb_M a{margin:0 12px}

.Header_header__hHwG5,.Header_header__hHwG5 ul{display:flex;flex-direction:row}.Header_header__hHwG5 a{text-decoration:none;color:#dce1de}.Header_header__hHwG5{background-color:#216869;justify-content:space-between;align-items:center;padding:0 12px}.Header_header__hHwG5 a{padding:0px 4px}.Header_header__hHwG5 li{margin:0 4px}.Header_home__gG2su{font-size:1.5rem}

.App_app__2ziFi{display:flex;flex-direction:column;min-height:100vh;max-width:100%}.App_app__2ziFi section{padding-left:40px;padding-right:40px}.App_page__1AVsN{height:inherit;display:flex;flex-direction:column;max-width:100%}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}

html{font-size:100%}body{background:white;font-family:"Inconsolata", Helvetica, sans-serif;font-weight:400;line-height:1.75;color:#000000}p{margin-bottom:1rem}h1,h2,h3,h4,h5{margin:1.5rem 0 1rem;font-family:"Inconsolata", Helvetica, sans-serif;font-weight:600;line-height:1.3}h1{margin-top:0;font-size:3.052rem}h2{font-size:2.441rem}h3{font-size:1.563rem}h4{font-size:1.45rem}h5{font-size:1.25rem}small,.text_small{font-size:0.8rem}


