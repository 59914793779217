@import "../../theme";

.footer {
  background-color: $base-secondary-color;
  @extend %flex-row;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  min-height: 20px;
  ul {
    @extend %flex-row;
  }
  li {
    margin: 0 4px;
  }
  a {
    @extend %nav-font;
    margin: 0 12px;
  }
}
