@import "../../theme";

.header {
  @extend %flex-row;
  background-color: $base-color;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  a {
    @extend %nav-font;
    padding: 0px 4px;
  }
  ul {
    @extend %flex-row;
  }
  li {
    margin: 0 4px;
  }
}

.home {
  font-size: 1.5rem;
}
